import React from "react"
import {
  Flex,
  Block,
  styled,
  useTheme,
  IconSearch,
} from "@sonos-inc/web-ui-core"
import Link from "./Link"

const FlexAsUl = styled(Flex.withComponent("ul"))({
  listStyle: "none",
})
const BlockAsNav = Block.withComponent("nav")

const StyledIconSearch = styled(IconSearch)({
  "@media (min-width: 64rem)": {
    height: "39px",
  },
})

export default function MainNav() {
  const theme = useTheme()
  const singleColumnRatio = 1 / theme.grid.columns
  const twoColumnPercentage = `${singleColumnRatio * 2 * 100}%`
  // TODO: should come from CMS (?)

  const links = [
    {
      url: "/search",
      label: <StyledIconSearch fontSize="1rem" />,
    },
    {
      url: "/",
      label: "Home",
    },
    {
      url: "/about",
      label: "About",
    },
    {
      url: "https://developer.sonos.com",
      label: "Developers",
      showExternalLinkIcon: true,
    },
    {
      url: "https://www.sonos.com/life-at-sonos",
      label: "Career",
      showExternalLinkIcon: true,
    },
  ]
  return (
    <BlockAsNav
      position={[null, null, null, "fixed"]}
      left={
        /* 1/ desktop: position in alignment with second column in main grid */
        [null, null, null, twoColumnPercentage]
      }
    >
      <FlexAsUl
        flexDirection={[null, null, null, "column"]}
        justifyContent="space-between"
        alignItems={["center", null, null, "initial"]}
        mb={["large", null, null, "nil"]}
        pl={[twoColumnPercentage, null, null, "nil"]}
        pr={[twoColumnPercentage, null, null, "nil"]}
      >
        {links.map(({ url, label, showExternalLinkIcon }) => {
          return (
            <li key={url}>
              <Link
                href={url}
                mb={[null, null, null, "large"]}
                pl={
                  /* 2/ desktop: null left padding for perfect grid alignment */
                  [null, null, null, "nil"]
                }
                color="currentColor"
                fontWeight="medium"
                customStyles={{
                  fontSize: "0.75rem",
                  lineHeight: 1.4,
                  "@media (min-width: 23.5em)": {
                    fontSize: "0.875rem",
                  },
                  "@media (min-width: 40em)": {
                    lineHeight: 1.5,
                  },
                }}
                activeStyles={{ textDecoration: "underline" }}
                showExternalLinkIcon={showExternalLinkIcon}
              >
                {label}
              </Link>
            </li>
          )
        })}
      </FlexAsUl>
    </BlockAsNav>
  )
}

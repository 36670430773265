import React from "react"
import { GlobalStyles, Block, ThemeProvider } from "@sonos-inc/web-ui-core"
import { jsx } from "@emotion/core"
/** @jsx jsx */

import Header from "./Header"
import MainNav from "./MainNav"
import Footer from "./Footer"
import SEO from "./SEO"
import { techBlogTheme } from "../constants/layout"

if (typeof window !== "undefined") {
  import(/* webpackChunkName: "analytics" */ "../utils/analytics.tsx").then(
    ({ init: initAnalytics }) => {
      initAnalytics()
    }
  )
}

export default function Layout({ children }) {
  return (
    <ThemeProvider theme={techBlogTheme}>
      {/* default SEO attributes, can be overriden (partially or completely) by pages */}
      <SEO />
      <GlobalStyles
        useDarkMode={false}
        customStyles={{
          "#___gatsby, html, body": { height: "100%" },
          "#gatsby-focus-wrapper": {
            height: "100%",
            display: "flex",
            flexDirection: "column",
          },
        }}
      />
      <Block
        position="relative"
        css={{
          width: "100vw",
          flexGrow: 1,
          "@media (min-width: 100rem)": {
            width: "100rem",
          },
        }}
        ml="auto"
        mr="auto"
      >
        <Header />

        <MainNav />

        <main>{children}</main>
      </Block>
      <Footer />
    </ThemeProvider>
  )
}

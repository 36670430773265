import React from "react"
import { jsx } from "@emotion/core"
/** @jsx jsx */

import { Block, css } from "@sonos-inc/web-ui-core"

const baseStyles = css({
  // (better to change color.base ?)
  color: "#979797",
})
const upperCaseStyles = css({
  color: "#979797",
  textTransform: "uppercase",
  letterSpacing: "0.5px",
})

export default function Label({ children, upperCase, ...restProps }) {
  return (
    <Block
      css={upperCase ? upperCaseStyles : baseStyles}
      fontWeight={upperCase ? "medium" : undefined}
      fontSize={upperCase ? ["12px", null, "14px"] : "14px"}
      {...restProps}
    >
      {children}
    </Block>
  )
}

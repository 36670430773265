import React from "react"

import { Flex, Block, LogoSonos } from "@sonos-inc/web-ui-core"
import Label from "./Label"
import Link from "./Link"

const LogoSonosBlock = Block.withComponent(LogoSonos)

const tierLabel =
  process.env.GATSBY_BUILD_FOR_TIER &&
  process.env.GATSBY_BUILD_FOR_TIER !== "production"
    ? process.env.GATSBY_BUILD_FOR_TIER
    : null

export default function Header() {
  return (
    <header>
      <Flex
        justifyContent="center"
        alignItems="center"
        mb={["-0.5rem", null, null, "large"]}
      >
        <Link href="/" customStyles={{ "&:hover": {} }}>
          <LogoSonosBlock
            color="primary"
            width={["70px", null, null, "90px"]}
            height={["70px", null, null, "90px"]}
            mr={["small", null, null, "11px"]}
          />
          <Label fontWeight="medium">
            Tech Blog
            {tierLabel && (
              <span
                style={{
                  backgroundColor: "red",
                  color: "black",
                  textTransform: "uppercase",
                  marginLeft: "4px",
                  padding: "0 4px",
                }}
              >
                {tierLabel}
              </span>
            )}
          </Label>
        </Link>
      </Flex>
    </header>
  )
}

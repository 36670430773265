import React from "react"

import { Helmet } from "react-helmet"

const siteTitle = "Sonos Tech Blog"
const siteDescription =
  "Read more about the unique combination of software and hardware that powers the Sonos ecosystem. Welcome to our technology blog."
const twitterCreator = "@sonos"
const imageShareUrl =
  "https://media.sonos.com/images/znqtjj88/production/7e1561b6215907eeba34281d0fa3c0fe7805a958-2048x1119.jpg?w=1200&auto=format"

export default function SEO({
  title,
  description = siteDescription,
  imageShare = imageShareUrl,
  lang = "en",
  meta = [],
}) {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title || siteTitle}
      titleTemplate={title ? `%s | ${siteTitle}` : undefined}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: "og:image",
          content: imageShare,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: twitterCreator,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          property: "twitter:image",
          content: imageShare,
        },
      ].concat(meta)}
    />
  )
}

import { theme } from "@sonos-inc/web-ui-core"

export const defaultMobileGridTemplateColumns =
  "[start] repeat(2, 1fr) [content-start] repeat(23, 1fr) [content-end] repeat(2, 1fr)"
export const defaultMobileGridContentColumn = "content-start / content-end"

export const techBlogTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    base: "#fafafa",
  },
}

export const defaultGridTemplateColumns = [
  "repeat(27, 1fr)",
  null,
  "repeat(24, 1fr)",
  "repeat(27, 1fr)",
]

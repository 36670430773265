import { Block, css } from "@sonos-inc/web-ui-core"
import { jsx } from "@emotion/core"
/** @jsx jsx */

const BlockAsH1 = Block.withComponent("h1")
const BlockAsH2 = Block.withComponent("h2")
const BlockAsH3 = Block.withComponent("h3")
const BlockAsH4 = Block.withComponent("h4")

export function H1(props) {
  const Element = props.Element || BlockAsH1
  return (
    <Element
      fontSize={["ts2p5", null, "ts3"]}
      fontWeight="medium"
      css={css({
        letterSpacing: "0.1px",
        lineHeight: ["1.14", null, "1.125"],
      })}
      mb={["medium", null, "large"]}
      {...props}
    />
  )
}

export function H2(props) {
  const Element = props.Element || BlockAsH2
  return (
    <Element
      fontSize={["ts2", null, "ts2p5"]}
      fontWeight="medium"
      css={css({
        letterSpacing: "0.1px",
        lineHeight: ["1.18", null, "1.15"],
      })}
      mb={["medium", null, "large"]}
      {...props}
    />
  )
}

export function H3(props) {
  const Element = props.Element || BlockAsH3
  return (
    <Element
      fontSize={["bodyCopy", null, "ts2"]}
      fontWeight="medium"
      css={css({
        letterSpacing: "0.2px",
        lineHeight: ["1.25", null, "1.2"],
      })}
      mb={["medium", null, "large"]}
      {...props}
    />
  )
}

export function H4(props) {
  const Element = props.Element || BlockAsH4
  return (
    <Element
      textStyle="bodyCopy"
      fontWeight="medium"
      css={css({ letterSpacing: "0.2px" })}
      mb={["medium", null, "large"]}
      {...props}
    />
  )
}

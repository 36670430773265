import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { jsx } from "@emotion/core"
/** @jsx jsx */

import { Flex, Block, IconArrowBoldRight, styled } from "@sonos-inc/web-ui-core"

const BlockAsIconArrowTopRight = styled(
  Block.withComponent(IconArrowBoldRight)
)({
  transform: "rotateZ(-45deg)",
  display: "none",
  "@media (min-width: 40em)": {
    display: "inline",
  },
})

const NavLinkInternal = Flex.withComponent(GatsbyLink)
const NavLinkExternal = Flex.withComponent("a")

const baseStyles = {
  display: "inline-flex",
  letterSpacing: "0.4px",
}
const linkStyleProps = {
  color: "primary",
  textStyle: "ts1",
  fontWeight: "medium",
}
export default function Link({
  href,
  children,
  isPlain,
  isUnstyled,
  customStyles,
  activeStyles,
  showExternalLinkIcon,
  NonLinkElement,
  ...linkProps
}) {
  let LinkComponent = NonLinkElement
  let hrefProp = {}
  if (!NonLinkElement) {
    const isExternalLink =
      href?.indexOf("http") === 0 || href?.indexOf("mailto:") === 0
    LinkComponent = isExternalLink ? NavLinkExternal : NavLinkInternal
    hrefProp = isExternalLink ? { href } : { to: href }
  }

  let linkStyles = isUnstyled
    ? { textDecoration: "none", color: "currentColor" }
    : isPlain
    ? { ...baseStyles, color: "currentColor" }
    : {
        ...baseStyles,
        textDecoration: "none",
        "&:hover": {
          textDecoration: "underline",
        },
      }
  const styles = { ...linkStyles, ...customStyles }

  return (
    <LinkComponent
      {...(!(isPlain || isUnstyled || customStyles) && linkStyleProps)}
      alignItems="center"
      css={styles}
      activeStyle={activeStyles}
      {...linkProps}
      {...hrefProp}
    >
      {children}
      {showExternalLinkIcon && (
        <BlockAsIconArrowTopRight
          ml={["0.14em", "0.42em"]}
          width="1.286em"
          height="auto"
        />
      )}
    </LinkComponent>
  )
}

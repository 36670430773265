import { jsx } from "@emotion/core"
/** @jsx jsx */
import { css, useTheme } from "@sonos-inc/web-ui-core"
import { PAsBlock } from "./blocks"

export default function Footer() {
  const theme = useTheme()
  const singleColumnRatio = 1 / theme.grid.columns
  const twoColumnPercentage = `${singleColumnRatio * 2 * 100}%`
  return (
    <footer
      css={css({
        textAlign: "center",
        backgroundColor: "#F2F2F2",
        pt: ["big", null, "huge"],
        pl: twoColumnPercentage,
        pr: twoColumnPercentage,
        pb: ["60px", null, "huge"],
      })}
    >
      <PAsBlock textStyle="caption">
        © {new Date().getFullYear()} by Sonos. Inc.
        <br />
        All rights reserved. Sonos and Sonos product names are trademarks or
        registered trademarks of Sonos, Inc.
        <br />
        All other product names and services may be trademarks or service marks
        of their respective owners. Sonos, Inc.
      </PAsBlock>
    </footer>
  )
}
